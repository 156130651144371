import data from "../../constants/static.json";
import { Link } from 'react-router-dom';
import { YoutubeIcon, LinkedinIcon, TwitterIcon } from "lucide-react"
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
	const FooterData = data.FooterData;
	return (
		<footer className='footer'>
			<div className='footer__Container'>
				{/* Footer Top */}
				<div className='footer__Top'>
					<Link to='/' className='footer__Top--Left'>
						<img src='/HMSLogo.png' alt='hms-logo' />
					</Link>
					<div className='footer__Top--Right'>
						<Link
							to='https://www.linkedin.com/company/horizon-market-strategies/'
							target='_blank'>
							{/* <img
								src='/LinkedInIcon.svg'
								alt='linkedin-icon'
							/> */}
							{/* <LinkedinIcon className="w-16 h-16 hover:text-blue-800" /> */}
							<SocialIcon network="linkedin" style={{ height: 40, width: 40 }} />
						</Link>
						<Link
							to='https://www.youtube.com/@HorizonmarketStrategies'
							target='_blank'>
							{' '}
							{/* <img
								src='/youtubeFooterIcon.webp'
								alt='youtube-icon'
							/> */}
							{/* <YoutubeIcon className="w-20 h-20 hover:text-red-600" /> */}
							<SocialIcon network="youtube" style={{ height: 40, width: 40 }} />
						</Link>
						<Link
							to='https://x.com/HorizonMarkets'
							target='_blank'>
							{' '}
							{/* <img
								src='/x-logo.png'
								alt='x-icon'
							/> */}
							{/* <TwitterIcon className="w-16 h-16 hover:text-blue-400" /> */}
							<SocialIcon network="x" style={{ height: 40, width: 40 }} />
						</Link>
					</div>
				</div>
				{/* Footer Middle */}
				<div className='footer__Middle'>
					{FooterData.map((item) => (
						<div className='footer__Links' key={item.id}>
							<h3>{item.title}</h3>
							{item.links.map((link) => (
								<div className='footer__Link' key={link.id}>
									<Link
										to={link.url}
										className='footer__Item'>
										{link.name}
									</Link>
								</div>
							))}
						</div>
					))}
				</div>
				{/* Contact */}
				{/* <div className='footer__Number'>
					<a href='tel:+447700142117'>+44 7700 142117</a>
					<a href='tel:+12136167815'>+1 213 616 7815</a>
				</div> */}
				<div className="h-0 border-b-[0.1rem] border-b-black w-full mt-[1.5rem]" />
				{/* Footer Bottom */}
				<div className="footer__Bottom">
					{/* <p><a href='mailto:info@horizon-markets.com'>Write Us</a></p> */}
					<p><Link to="/cookie-policy">Cookie Policy</Link></p>
					<p><Link to="/privacy">Privacy Policy</Link></p>
					<p><Link to="/refund-policy">Terms & Conditions</Link></p>
					<p>© Horizon Market Strategies {new Date().getFullYear()}. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
