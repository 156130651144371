import { useEffect, useState, memo } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { config } from '../../config';

// Home Cards :
const CardHome = memo(({ data }) => {
	return (
		<article className='cardHome'>
			<img src={data.url} alt={data.url} />
			<h3>{data.heading}</h3>
			<p>{data.details}</p>
		</article>
	);
});

export default CardHome;

// Service Cards :
export const CardServiceHome = memo(({ data }) => {
	return (
		<article
			className='cardServiceHome'
			data-testid='service-card'>
			<div className='cardServiceHome__Wrapper'>
				<img src={`${config.backend.strapi}${data.icon.url}`} alt={data.id} />
			</div>
			<h1>{data.heading}</h1>
			<p>{data.body}</p>
		</article>
	);
});

// Reports Cards :
export const CardReportsHome = memo(({ data, onClick }) => {
	const formatDate = (dateString) => {
		const dateObject = new Date(dateString);
		const options = {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		};
		return dateObject.toLocaleDateString('en-US', options);
	};

	const [showImage, setShowImage] = useState(false);

	const formattedDate = formatDate(data.publishDate);
	const [month, day, year] = formattedDate.split(' ');

	return (
		<Link
			to={`/report/${data.slug}`}
			data-testid='report-cards'
			className='cardReportsHome'
			onClick={onClick}
			onMouseEnter={() => setShowImage(true)}
			onMouseLeave={() => setShowImage(false)}
		>
			<div className='cardReportsHome__Data'>
				<div className='cardReportsHome__Top'>
					<h1>{`${data.category.name}, ${data.sub_category.name}`}</h1>
					<span>{data.title}</span>
				</div>
			</div>
			<div className='cardReportsHome__Image'>
				<div className={`absolute bottom-0 left-0 pl-8 pb-14 text-[1.6rem] font-[400] text-black uppercase ${showImage ? 'opacity-0' : 'opacity-100'}`}>
					<h3 className='text-[1.2rem] font-[400] text-black capitalize'>Updated at:</h3>
					<h3>
						{month} {day}
					</h3>
					<h3>
						{year}
					</h3>
				</div>
				<img
					src={`${config.backend.strapi}${data.featuredImage?.formats?.medium?.url ?? data.featuredImage?.formats?.small?.url ?? data.featuredImage?.formats?.thumbnail?.url ?? data.featuredImage?.formats?.small?.url ?? data.featuredImage?.formats?.thumbnail?.url}`}
					alt={data.title}
					className={`bg-white w-full object-cover transition-all duration-200 ${showImage ? 'opacity-100' : 'opacity-0'}`}
				/>
			</div>
		</Link>
	);
});

// Teams Cards :
export const TeamsCard = memo(({ data }) => {
	return (
		<article
			data-testid='teams-card'
			className='teamsCard relative transition-transform duration-500 bg-[#D4D4D41C] ease-in-out hover:bg-[#042E3E] overflow-hidden group hover:text-[#FFF]'>
			<div className='teamCard__Initial w-full'>
				<img src={data.img} alt={String(data.id)} />
				<div className='teamsCard__Info'>
					<h4>{data.title}</h4>
					<p>{data.subTitle}</p>
				</div>
			</div>
			<div className='teamCard__HoverData'>
				<div className='teamCard__TopIcons'>
					{/* <Link
						to={data.URL}
						target='_blank'
						rel='noopener noreferrer'>
						<img src="/TeamsLinkedInIcon.svg" alt="" />
					</Link> */}
					{data.URL === null ? (
						''
					) : (
						<Link
							to={data.URL}
							target='_blank'
							rel='noopener noreferrer'>
							<img src='/TeamsLinkedInIcon.svg' alt='' />
						</Link>
					)}
				</div>
				<div className='teamCard__MidData text-justify'>{data.desc}</div>
			</div>
		</article>
	);
});

// Store Cards :
export const StoreCard = memo(
	({ img, title, price, id }) => {
		return (
			<article className='storeCard'>
				<img
					src='/StoreCardImg.svg'
					alt={title}
					className='store-card__img'
				/>
				<h3>{title}</h3>
				<div className='storeCard__Bottom'>
					<p>{price}$</p>
					<Link to={`/store/${id}`}>
						<div className='storeCard__Cart'>
							<img
								src='/StoreCartIcon.svg'
								alt='cart-icon'
							/>
						</div>
					</Link>
				</div>
			</article>
		);
	}
);

// Order Details Cards :
export const OrderDetailsCard = memo(
	({ img, title, price, id }) => {
		return (
			<article className='orderDetailsCard'>
				<img
					src={img}
					alt={title}
					className='store-card__img'
				/>
				<h3>{title}</h3>
				<div className='orderDetailsCard__Bottom'>
					<p>{price}$</p>
					<Link to={`/store/${id}`}>
						<div className='orderDetailsCard__Cart'>
							<img
								src={'/StoreCartIcon.svg'}
								alt='cart-icon'
							/>
						</div>
					</Link>
				</div>
			</article>
		);
	}
);

// Cart Cards :
export const CartCard = memo(
	({ id, img, title, price }) => {
		return (
			<article className='cartCard'>
				<div className='cartCard__Left'>
					<img src={img} alt={String(id)} />
				</div>
				<div className='cartCard__Right'>
					<h1>{title}</h1>
					<div className='cartCard__Options'>
						<div className='cartCard__Btns'>
							<button>-</button>
							<span>1</span>
							<button>+</button>
						</div>
						<p>{price}$</p>
					</div>
					<button
						type='button'
						className='cartCard__RemoveBtn'>
						Remove
					</button>
				</div>
			</article>
		);
	}
);

// Blog Cards :
export const BlogCard = memo(
	({ urlSlug, img, title, content, urlParam = 'blog' }) => {
		return (
			<Link to={`/${urlParam}/${urlSlug}`}>
				<article className='blogCard'>
					<div className='w-2/3'>
						<h3>{title}</h3>
						<p>{content.length > 180 ? content.slice(0, 180) + '...' : content}</p>
					</div>
					<div className='flex w-1/3 justify-between'>
						<img src={`${config.backend.strapi}${img}`} alt={urlSlug} />
					</div>
				</article>
			</Link>
		);
	}
);

export const BlogArchiveCard = memo(({ data }) => {
	return (
		<Link to={`/blogs/${data.slug}`} className='blogArchiveCard relative'>
			<img src={`${config.backend.strapi}${data?.featuredImage?.formats?.thumbnail?.url}`} alt={data.title} className='blogArchiveCard__Img' />

			<div className='px-6 py-8 flex flex-col gap-4'>
				<h3 className='text-[1.4rem] font-[400] text-[#acacb2]'>{new Date(data.publishedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h3>

				<h3 className='text-[1.8rem] font-[600] leading-tight text-[#1E1E1E]'>{data.title}</h3>

				<p className='text-[1.6rem] font-[400] text-[#1E1E1E] leading-relaxed'>{data.seo.metaDescription.length > 80 ? data.seo.metaDescription.slice(0, 80) + '...' : data.seo.metaDescription}</p>

				<span className='text-[1.4rem] w-fit font-[500] text-[#5451D6] hover:text-[#1e1e1e]'>Read More</span>
			</div>
		</Link>
	);
});

// NEWS CARD :
export const NewsCard = memo(({ data }) => {
	return (
		<Link to={`/industry-news/${data.slug}`}>
			<article className='newsCard'>
				<div className='newsCard__Date'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='#fff'
						className='size-8'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5'
						/>
					</svg>
					<p>{new Date(data.publishDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
				</div>
				<h3>{data.title}</h3>
				<p>{data.description && HTMLReactParser(data.description)}</p>
				<span>Read More</span>
			</article>
		</Link>
	);
});
