import { apiClient } from './apiclient';

export const getBlogs = async (params) => {
	const apiUrl = `/blogs?sort=createdAt:desc&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}&populate=*`;

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		// params: params,
	});
	return res;
};

export const getDetailedBlog = async (params) => {
	const apiUrl = `/blogs?filters[slug][$eq]=${params.urlSlug}&populate=author.authorProfilePic&populate=author.social&populate=seo`;

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
