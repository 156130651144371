import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button.component';

const About = () => {
	return (
		<main className='about'>
			<Helmet>
				<title>About Us - Horizon Market Strategies</title>
				<meta name="description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:title" content="About Us - Horizon Market Strategies" />
				<meta property="og:description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:site_name" content="Horizon Market Strategies" />
			</Helmet>
			<div className='about__Container'>
				<section className='about__One'>
					<div className='about__Left'>
						<img
							src={'/AboutImgOne.svg'}
							alt='about-img-one'
						/>
					</div>
					<div className='about__Right'>
						<h4>GET TO KNOW HMS:</h4>
						<h1>
							About <span>US</span>
						</h1>
						<p>
							Horizon Market Strategies (HMS), your trusted
							partner in delivering bespoke market research
							solutions aimed at equipping businesses with
							actionable insights. Leveraging cutting-edge
							methodologies, our seasoned researchers engage
							in meticulous data gathering and analysis,
							syndicated research, and growth consulting
							initiatives. This process unveils critical
							trends, opportunities, and challenges specific
							to your industry, ensuring informed
							decision-making.
						</p>
					</div>
				</section>
				<section className='about__Two'>
					<div className='about__TwoLeft'>
						<h4>SPECIALIZATION:</h4>
						<h1>
							Our <span>Capabilities</span>
						</h1>
					</div>
					<div className='about__TwoRight'>
						<p>
							Our specialized team excels in market &
							competitive intelligence, management and
							strategy consulting, pre-investment advisory,
							real-time business intelligence, and data
							analytics services. Operating across sectors,
							both globally and locally, we focus on new &
							emerging markets and disruptive technologies.
							With a commitment to excellence, we empower
							our clients to navigate the complexities of
							today&apos;s marketplace with confidence and
							precision.
						</p>
					</div>
				</section>
				<section className='about__Three'>
					<h4>STATEMENTS:</h4>
					<h1>
						Our <span>Mission & Vision</span>
					</h1>
					<div className='about__ThreeContainer'>
						<div className='about__ThreeLeft'>
							<h4>MISSION:</h4>
							<p>
								Our mission is to serve as a premier partner
								for businesses seeking strategic market
								insights. Through cutting-edge research
								methodologies and tailored solutions, we aim
								to deliver actionable intelligence that
								empowers informed decision-making. Our
								objective is to enable clients to excel in
								the competitive landscape of the global
								market with confidence and professionalism.
							</p>
							<h4>VISION:</h4>
							<p>
								To redefine the landscape of market
								intelligence by pioneering innovative
								research methodologies and delivering
								unparalleled insights. We aspire to be the
								catalyst for informed decision-making,
								empowering businesses worldwide to navigate
								dynamic markets with confidence and
								foresight. With a relentless commitment to
								excellence and a passion for uncovering new
								possibilities, we strive to be the trusted
								partner that propels our clients towards
								sustainable success in an ever-evolving
								business environment.
							</p>
						</div>
						<div className='about__ThreeRight'>
							<img src="/AboutImgOne.jpg" alt='' />
						</div>
					</div>
				</section>
				<section className='about__Four'>
					<h1>
						Our <span>Value Proposition</span>
					</h1>
					<div className='about__PropOne'>
						<p>
							TAILORED SOLUTIONS:{' '}
							<span>
								Your unique objectives are at the heart of
								our approach. We craft bespoke market
								research solutions, ensuring relevance and
								effectiveness for your specific needs.
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							ACTIONABLE INSIGHTS:{' '}
							<span>
								Our focus is on delivering insights that
								drive action. With our research
								methodologies, you will receive clear,
								practical guidance to inform your strategic
								decision-making processes.
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							EXPERTISE AND EXPERIENCE:{' '}
							<span>
								{' '}
								Benefit from the expertise of our seasoned
								team. With years of industry experience, we
								bring unparalleled knowledge and insights to
								every project we undertake.
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							COMPREHENSIVE SERVICES:{' '}
							<span>
								From market analysis to strategic
								consulting, we offer a comprehensive suite
								of services to support your business needs
								at every stage of development.
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							GLOBAL REACH:{' '}
							<span>
								Gain valuable insights into both established
								and emerging markets worldwide. Our
								capabilities extend across sectors and
								regions, ensuring you stay ahead in a
								constantly evolving global landscape.
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							TIMELY DELIVERY:{' '}
							<span>
								We understand the urgency of your decisions.
								Our efficient processes ensure timely
								delivery of results without compromising on
								quality, enabling you to act swiftly and
								confidently.{' '}
							</span>
						</p>
					</div>
					<div className='about__PropOne'>
						<p>
							LONG-TERM PARTNERSHIPS:{' '}
							<span>
								We're more than just service providers;
								we're your trusted partners in success.
								Build lasting relationships with us as we
								work together to drive your business
								forward.{' '}
							</span>
						</p>
					</div>
				</section>

			</div>
			<section className="about__Five">
				<div className="about__CommunityLeft">
					<h1>
						Join the <span>community</span>
					</h1>
					<p>Connect with industry leaders and experts. Join our

						community for networking and knowledge-sharing</p>
					<Link to="/contact-us"><Button text='Free Consultation' className='button__Common w-fit mt-[2rem] px-[6rem] uppercase' /></Link>
				</div>
				<div className="about__CommunityRight">
					<img src="/aboutUsImage.jpg" alt="community-image" />
				</div>
			</section>
		</main>
	);
};

export default About;
