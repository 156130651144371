import React from 'react';
import { Button, TeamsCard } from '../../components';
// import { TeamsCardData } from '../../constants/Data';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import data from "../../constants/static.json";

const Teams = () => {
	const TeamsCardData = data.TeamsCardData;
	console.log(TeamsCardData);
	return (
		<main className='teams'>
			<Helmet>
				<title>Meet Our Team - Horizon Market Strategies</title>
				<meta name="description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:title" content="Meet Our Team - Horizon Market Strategies" />
				<meta property="og:description" content="Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights." />
				<meta property="og:site_name" content="Horizon Market Strategies" />
			</Helmet>
			<div className='teams__Container'>
				{/* Top */}
				<div className='teams__Top'>
					<p>TEAM:</p>
					<div className='teams__Header'>
						<h1>
							A Team of <span>Experts</span>
						</h1>
						<Link to="/contact-us">
							<Button
								type='button'
								text='Contact'
								className='button__Common uppercase'
							/>
						</Link>
					</div>
				</div>
				{/* Middle */}
				<div className='teams__Middle'>
					<h4>Faster turnaround times:</h4>
					<p>
						Welcome to the heart of our operation - where innovation meets dedication, and expertise merges with passion. Allow us to introduce you to the exceptional individuals who drive our mission forward every day.
					</p>
				</div>
				{/* Bottom */}
				{/* <div className='teams__BottomOne'>
					{TeamsCardData.slice(0, 3).map((item) => (
						<TeamsCard key={item.id} data={item} />
					))}
				</div>
				<div className='teams__BottomTwo'>
					{TeamsCardData.slice(-2).map((item) => (
						<TeamsCard key={item.id} data={item} />
					))}
				</div> */}
				<div className='teams__BottomOne'>
					{TeamsCardData.map((item) => (
						<TeamsCard key={item.id} data={item} />
					))}
				</div>
			</div>
		</main>
	);
};

export default Teams;
